import Spinner from 'components/icons/Spinner';

interface ButtonProps {
  title?: string;
  isLoading?: boolean;
}

const Button = ({
  isLoading = false,
  title,
  children,
  ...buttonProps
}: ButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  const { className, ...restButtonProps } = buttonProps;

  return (
    <button
      className={`w-full flex justify-center py-2 px-4 border border-summer-green-500 text-sm font-medium rounded-md text-san-juan-blue-500 bg-summer-green-400 hover:bg-summer-green-500 focus:outline-none transition duration-150 ease-in-out disabled:bg-gray-400 disabled:cursor-not-allowed ${className}`}
      {...restButtonProps}
    >
      {isLoading ? (
        <Spinner width="20" fill="#304D63" className="animate-spin" />
      ) : (
        title
      )}
      {children}
    </button>
  );
};

export default Button;
